import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
  {
    path: 'reset-password',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/default/ResetPassword.vue')
  }
]

// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: false },
    component: () => import('@/views/dashboards/IndexPage.vue')
  },
  // Company Pages
  {
    path: '/company-list',
    name: prefix + '.company-list',
    meta: { auth: true, name: 'Company List', isBanner: true },
    component: () => import('@/views/company/ListPage.vue')
  },
  {
    path: '/company-add',
    name: prefix + '.company-add',
    meta: { auth: true, name: 'Company Add', isBanner: true },
    component: () => import('@/views/company/AddPage.vue')
  },
  {
    path: '/company-edit/:id',
    name: prefix + '.company-edit',
    meta: { auth: true, name: 'Company Edit', isBanner: true },
    component: () => import('@/views/company/EditPage.vue')
  },
  // Plug Interface Pages
  {
    path: '/plug-interface-list',
    name: prefix + '.plug-interface-list',
    meta: { auth: true, name: 'Plug Interface List', isBanner: true },
    component: () => import('@/views/plug-interface/ListPage.vue')
  },
  {
    path: '/plug-interface-add',
    name: prefix + '.plug-interface-add',
    meta: { auth: true, name: 'Plug Interface Add', isBanner: true },
    component: () => import('@/views/plug-interface/AddPage.vue')
  },
  {
    path: '/plug-interface-edit/:id',
    name: prefix + '.plug-interface-edit',
    meta: { auth: true, name: 'Plug Interface Edit', isBanner: true },
    component: () => import('@/views/plug-interface/EditPage.vue')
  },
  // Calculation Strategies Pages
  /*
  {
    path: '/calculation-strategy-list',
    name: prefix + '.calculation-strategy-list',
    meta: { auth: true, name: 'Plug Interface List', isBanner: true },
    component: () => import('@/views/calculation-strategy/ListPage.vue')
  },
  {
    path: '/calculation-strategy-add',
    name: prefix + '.calculation-strategy-add',
    meta: { auth: true, name: 'Plug Interface Add', isBanner: true },
    component: () => import('@/views/calculation-strategy/AddPage.vue')
  },
  {
    path: '/calculation-strategy-edit/:id',
    name: prefix + '.calculation-strategy-edit',
    meta: { auth: true, name: 'Plug Interface Edit', isBanner: true },
    component: () => import('@/views/calculation-strategy/EditPage.vue')
  },
  */
  // Charging Station Pages
  {
    path: '/charging-station-list',
    name: prefix + '.charging-station-list',
    meta: { auth: true, name: 'Charging Station List', isBanner: true },
    component: () => import('@/views/charging-station/ListPage.vue')
  },
  {
    path: '/charging-station-add',
    name: prefix + '.charging-station-add',
    meta: { auth: true, name: 'Charging Station Add', isBanner: true },
    component: () => import('@/views/charging-station/AddPage.vue')
  },
  {
    path: '/charging-station-edit/:id',
    name: prefix + '.charging-station-edit',
    meta: { auth: true, name: 'Charging Station Edit', isBanner: true },
    component: () => import('@/views/charging-station/EditPage.vue')
  },
  // Charger Pages
  {
    path: '/charger-list',
    name: prefix + '.charger-list',
    meta: { auth: true, name: 'Charger List', isBanner: true },
    component: () => import('@/views/charger/ListPage.vue')
  },
  {
    path: '/charger-add',
    name: prefix + '.charger-add',
    meta: { auth: true, name: 'Charger Add', isBanner: true },
    component: () => import('@/views/charger/AddPage.vue')
  },
  {
    path: '/charger-edit/:id',
    name: prefix + '.charger-edit',
    meta: { auth: true, name: 'Charger Edit', isBanner: true },
    component: () => import('@/views/charger/EditPage.vue')
  },
  {
    path: '/ev-operators-list',
    name: prefix + '.ev-operators-list',
    meta: { auth: true, name: 'EV Operators List', isBanner: true },
    component: () => import('@/views/ev-operators/ListPage.vue')
  },
  {
    path: '/ev-operator-add',
    name: prefix + '.ev-operator-add',
    meta: { auth: true, name: 'EV Operator Add', isBanner: true },
    component: () => import('@/views/ev-operators/AddPage.vue')
  },
  {
    path: '/ev-operator-edit/:id',
    name: prefix + '.ev-operator-edit',
    meta: { auth: true, name: 'EV Operators Edit', isBanner: true },
    component: () => import('@/views/ev-operators/EditPage.vue')
  },
  {
    path: '/ev-operator-mapping',
    name: prefix + '.ev-operator-mapping',
    meta: { auth: true, name: 'EV Operators Charger Mapping', isBanner: true },
    component: () => import('@/views/ev-operators/MappingPage.vue')
  },
  {
    path: '/user-list',
    name: prefix + '.user-list',
    meta: { auth: true, name: 'User List', isBanner: true },
    component: () => import('@/views/user/ListPage.vue')
  },
  {
    path: '/user-add',
    name: prefix + '.user-add',
    meta: { auth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/user/AddPage.vue')
  },
  {
    path: '/user-edit/:id',
    name: prefix + '.user-edit',
    meta: { auth: true, name: 'User Edit', isBanner: true },
    component: () => import('@/views/user/EditPage.vue')
  },
  {
    path: '/user-profile',
    name: prefix + '.user-profile',
    meta: { auth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/user/ProfilePage.vue')
  },
  {
    path: '/privacy-setting',
    name: prefix + '.user-privacy-setting',
    meta: { auth: true, name: 'Privacy Setting', isBanner: true },
    component: () => import('@/views/user/PrivacySetting.vue')
  },
  {
    path: '/privacy-policy',
    name: prefix + '.privacy-policy',
    meta: { auth: true, name: 'Privacy Policy', isBanner: true },
    component: () => import('@/views/extra/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: prefix + '.terms-and-conditions',
    meta: { auth: true, name: 'Terms and Conditions', isBanner: true },
    component: () => import('@/views/extra/TermsAndConditions.vue')
  },
  {
    path: '/admin-permissions',
    name: prefix + '.admin-permissions',
    meta: { auth: true, name: 'Admin Permissions', isBanner: true },
    component: () => import('@/views/admin/AdminPage.vue')
  },
  {
    path: '/session-list',
    name: prefix + '.session-list',
    meta: { auth: true, name: 'Session List', isBanner: true },
    component: () => import('@/views/session/ListPage.vue')
  },
  {
    path: '/session-detail/:id',
    name: prefix + '.session-detail',
    meta: { auth: true, name: 'Session Detail', isBanner: true },
    component: () => import('@/views/session/DetailPage.vue')
  },
  {
    path: '/shift-list',
    name: prefix + '.shift-list',
    meta: { auth: true, name: 'Shift List', isBanner: true },
    component: () => import('@/views/shift/ListPage.vue')
  },
  {
    path: '/active-shift',
    name: prefix + '.active-shift',
    meta: { auth: true, name: 'Active Shift', isBanner: true },
    component: () => import('@/views/shift/ActiveShiftPage.vue')
  },
  {
    path: '/previous-shift/:id',
    name: prefix + '.previous-shift',
    meta: { auth: true, name: 'Previous Shift', isBanner: true },
    component: () => import('@/views/shift/PreviousShiftPage.vue')
  },
  {
    path: '/session-list',
    name: prefix + '.session-list',
    meta: { auth: true, name: 'Session List', isBanner: true },
    component: () => import('@/views/session/ListPage.vue')
  },
  {
    path: '/session-summary',
    name: prefix + '.session-summary',
    meta: { auth: true, name: 'Session Summary', isBanner: true },
    component: () => import('@/views/session/SummaryPage.vue')
  },
  {
    path: '/status-notifications',
    name: prefix + '.status-notifications',
    meta: { auth: true, name: 'Status Notifications', isBanner: true },
    component: () => import('@/views/status-notifications/StatusNotification.vue')
  },
  {
    path: '/electricity-tariff-list',
    name: prefix + '.electricity-tariff-list',
    meta: { auth: true, name: 'Electricity Tariff List', isBanner: true },
    component: () => import('@/views/electricity-tariff/ListPage.vue')
  },
  {
    path: '/electricity-tariff-add',
    name: prefix + '.electricity-tariff-add',
    meta: { auth: true, name: 'Add Electricity Tariff', isBanner: true },
    component: () => import('@/views/electricity-tariff/AddPage.vue')
  },
  {
    path: '/electricity-tariff-edit/:id',
    name: prefix + '.electricity-tariff-edit',
    meta: { auth: true, name: 'Edit Electricity Tariff', isBanner: true },
    component: () => import('@/views/electricity-tariff/EditPage.vue')
  },
  {
    path: '/live-sessions',
    name: prefix + '.live-sessions',
    meta: { auth: true, name: 'Live Sessions', isBanner: true },
    component: () => import('@/views/session/LiveSessions.vue')
  },
  {
    path: '/live-chargers',
    name: prefix + '.live-chargers',
    meta: { auth: true, name: 'Live Chargers', isBanner: true },
    component: () => import('@/views/charger/RealTimeChargerList.vue')
  },
  {
    path: '/fleet-management',
    name: prefix + '.fleet-management',
    meta: { auth: true, name: 'Fleet Management', isBanner: true },
    component: () => import('@/views/fleet/FleetManagement.vue')
  },
  {
    path: '/fleet-management-edit/:id',
    name: prefix + '.fleet-management-edit',
    meta: { auth: true, name: 'Edit Fleet Management', isBanner: true },
    component: () => import('@/views/fleet/EditFleet.vue')
  },
  {
    path: '/fleet-group-management-edit/:id',
    name: 'FleetGroupManagementEdit',
    component: () => import('@/views/fleet/EditGroup.vue'),
    meta: { auth: true, name: 'Edit Group Management', isBanner: true },
  },
  {
    path: '/vehicle-management/:id',
    name: 'VehicleManagement',
    component: () => import('@/views/fleet/EditVehicle.vue'),
    meta: { auth: true, name: 'Vehicle Management', isBanner: true },
  }
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: false, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  },
  // Catch-all route for 404 - redirects to 404 page
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'errors.404' }
  }
]

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters['auth/isAuthenticated']) {
    next({ name: 'auth.login' })
  } else {
    next()
  }
})

export default router
